import React from 'react'
import PropTypes from 'prop-types'
import SEO from "../../components/seo"
import Layout from '../../containers/layout/layout'
import Header from '../../containers/layout/header/header-two'
import Footer from '../../containers/layout/footer/footer-one'
import PageHeader from '../../components/pageheader'
import Text from '../../components/ui/text'
import Section, {Row, Col} from '../../components/ui/wrapper'
import ProductInfoArea from '../../containers/product-list'
import CTA from '../../containers/global/cta-area/section-one'
import { ProdQuery } from '../../data/hooks/all-product-data'

const SpasProductsPage = ({ pageContext, location, ...restProps }) => {
    const {sectionStyle, bottomTextStyle} = restProps;
    const allProducts = ProdQuery();

    return (
        <Layout location={location}>
            <SEO title="Spas" />
            <Header />
            <PageHeader
                pageContext={pageContext}
                location={location}
                title="Spas"
            />
            <main className="site-wrapper-reveal">
                <Section {...sectionStyle}>
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <Text {...bottomTextStyle}>Discover the next generation of spas and hot tubs with H2O Spas!

                                With exclusive designs and unmatched quality, our spas, hot tubs and water accessories are the perfect addition for any home. With trending styles, innovative designs and unmatched customer service, we strive to make your spa experience a luxurious and rejuvenating affair!</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <ProductInfoArea
                                products={allProducts}
                                productType="Spas"
                            />
                        </Col>
                    </Row>
                </Section>
                <CTA/>
            </main>
            <Footer />
        </Layout>
    )
}

SpasProductsPage.propTypes = {
    sectionStyle: PropTypes.object,
    bottomTextStyle: PropTypes.object
}

SpasProductsPage.defaultProps = {
    sectionStyle: {
        pt: '40px',
        pb: '100px',
        responsive: {
            medium: {
                pt: '20px',
                pb: '80px',
            },
            small: {
                pt: '20px',
                pb: '60px'
            }
        }
    },
    bottomTextStyle: {
        fontSize: '18px',
        fontweight: 400,
        lineHeight: 1.40,
        color: '#333333',
        pb: '40px',
        align: 'center',
        responsive: {
            small: {
                pb: '25px',
                fontSize: '15px',
                lineHeight: 1.50,
            }
        }
    }
}

export default SpasProductsPage
